import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { LoyaltyEarningRule, Status } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { Reward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { CouponReference } from '@wix/ambassador-loyalty-v1-coupon/types';

export const createFixtures = async (flowAPI: ControllerFlowAPI, showCouponRewards: boolean) => {
  const { t } = flowAPI.translations;
  let earningRules: LoyaltyEarningRule[] = [
    {
      conversionRate: {
        moneyAmount: 1,
        points: 1,
      },
      status: Status.ACTIVE,
      title: t('app.fixtures.earning-rule-a.title'),
      triggerActivityType: 'wix-restaurants/orderSubmitted',
    },
    {
      fixedAmount: {
        points: 50,
      },
      status: Status.ACTIVE,
      title: t('app.fixtures.earning-rule-b.title'),
      triggerActivityType: 'auth/register',
    },
  ];
  const templateCouponId = '44d413ef-b654-46b6-a410-66966b3618ba';
  let rewards: Reward[] = [
    {
      active: true,
      discountAmount: {
        amount: '1',
      },
      name: t('app.fixtures.reward-a.title'),
      requiredPoints: 10,
      type: RewardType.DISCOUNT_AMOUNT,
    },
  ];

  if (showCouponRewards) {
    rewards.push({
      active: true,
      coupon: {
        templateId: templateCouponId,
      },
      name: t('app.fixtures.reward-b.title'),
      requiredPoints: 15,
      type: RewardType.COUPON,
    });
  }

  let coupons: CouponReference[] = [
    {
      couponId: templateCouponId,
      name: t('app.fixtures.coupon-a.display-name'),
      specification: {
        percentOffRate: 100,
      },
    },
  ];
  let description: string = '';

  return {
    earningRules,
    rewards,
    coupons,
    description,
  };
};
