import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { listEarningRules } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/http';
import { LoyaltyEarningRule } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { listRewards } from '@wix/ambassador-loyalty-v1-reward/http';
import { Reward } from '@wix/ambassador-loyalty-v1-reward/types';
import { queryCouponTemplate } from '@wix/ambassador-loyalty-v1-coupon/http';
import { CouponReference } from '@wix/ambassador-loyalty-v1-coupon/types';

export const loadData = async (flowAPI: ControllerFlowAPI) => {
  let earningRules: LoyaltyEarningRule[] = [];
  let rewards: Reward[] = [];
  let coupons: CouponReference[] = [];

  try {
    const { data } = await flowAPI.httpClient.request(listRewards({}));

    if (data.rewards) {
      rewards = data.rewards;
    }
  } catch (error) {
    throw error;
  }

  try {
    const { data } = await flowAPI.httpClient.request(listEarningRules({}));
    if (data.earningRules) {
      earningRules = data.earningRules;
    }
  } catch (error) {
    throw error;
  }

  const templateIds = rewards.filter((reward) => reward.coupon?.templateId).map((reward) => reward.coupon?.templateId);

  if (templateIds.length) {
    try {
      const { data } = await flowAPI.httpClient.request(
        queryCouponTemplate({
          query: {
            filter: JSON.stringify({ id: { $in: templateIds } }),
          },
        }),
      );

      if (data.couponReferences) {
        coupons = data.couponReferences;
      }
    } catch (error) {
      throw error;
    }
  }

  return {
    earningRules,
    rewards,
    coupons,
  };
};
